import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  private _url: string;
  private _options: any;

  private env: any = environment;

  public ToAddress: string;
  public ToName: string;
  public FromAddress: string;
  public FromName: string;
  public Subject: string;
  public Body: string;
  public IsHTML = false;

  constructor(
    private http: HttpClient
  ) {
    this._url = this.env.emailAPI;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this._options = { headers: headers };
  }

  send() {
    const body = {
      ToAddress: this.ToAddress,
      ToName: this.ToName,
      FromAddress: this.FromAddress,
      FromName: this.FromName,
      Subject: this.Subject,
      Body: this.Body,
      IsHTML: this.IsHTML ? 'true' : 'false'
      };

      return this.http.post(this._url, body, this._options);
  }

}
