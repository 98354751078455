import { ContactInfo } from './../../models/contact-info';
import { EmailService } from './../../services/email.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  contactInfo: ContactInfo = new ContactInfo('', '', '');
  isSubmitted = false;
  isSent = false;

  constructor(
    private email: EmailService
    ) { }

  ngOnInit() {
  }

  sendEmail() {
    this.isSubmitted = true;

    this.email.FromAddress = this.contactInfo.emailAddress;
    this.email.FromName = this.contactInfo.name;
    this.email.ToAddress = 'info@ghoulishmortals.com';
    this.email.ToName = 'info@inkfectioncollection.com';
    this.email.Subject = 'New Message from Website';
    this.email.Body = this.contactInfo.message;
    this.email.IsHTML = false;

    this.email.send()
      .subscribe(res => {
        this.isSent = true;
      });
  }

}
