import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { GtagModule } from 'angular-gtag';

import { AppComponent } from './app.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { HeaderComponent } from './components/header/header.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { FaqComponent } from './pages/faq/faq.component';
import { ContactComponent } from './pages/contact/contact.component';
import { ShopComponent } from './pages/shop/shop.component';
import { AboutComponent } from './pages/about/about.component';
import { EmailService } from './services/email.service';
import { ShopCustomComponent } from './pages/shop-custom/shop-custom.component';
import { ShopStockComponent } from './pages/shop-stock/shop-stock.component';
import { TermsComponent } from './pages/terms/terms.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { ReturnsComponent } from './pages/returns/returns.component';

const appRoutes = [
  { path: 'about', component: AboutComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'shop-stock', component: ShopStockComponent },
  { path: 'shop-custom', component: ShopCustomComponent },
  { path: 'shop', component: ShopComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'returns', component: ReturnsComponent },
  { path: '*', component: HomePageComponent },
  { path: '', component: HomePageComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HomePageComponent,
    HeaderComponent,
    NavigationComponent,
    FaqComponent,
    ContactComponent,
    ShopComponent,
    AboutComponent,
    ShopCustomComponent,
    ShopStockComponent,
    TermsComponent,
    PrivacyComponent,
    ReturnsComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes),
    GtagModule.forRoot({ trackingId: 'UA-53463374-3', trackPageviews: true })
  ],
  providers: [
    EmailService
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
