import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {

  navItems: { name: string, isHover: boolean, url: string}[] = [
    { name: 'about', isHover: false, url: 'about' },
    { name: 'faq', isHover: false, url: 'faq' },
    { name: 'shop', isHover: false, url: 'shop' },
    { name: 'contact', isHover: false, url: 'contact' }
  ];

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  getNavImage(navItem: any): string {
    let imgSrc = '../../../assets/nav-';
    imgSrc += navItem.name;
    imgSrc += '-';
    if (this.router.url.indexOf(navItem.name) !== -1) {
      imgSrc += 'on';
    } else {
      imgSrc += navItem.isHover ? 'on' : 'off';
    }
    imgSrc += '.png';
    return imgSrc;
  }
}
